'use strict';
var Tabulator = require('tabulator-tables');
var CBPChart = require('cb-p-chart');
var Ontology = require('ontology');

const Table = {
		translate : {ID : 'id', title: 'Title', year:'Publication date', cropCategory: 'Category / group', species: 'Species',
				abstract: "Abstract",
				authors: "Authors",
				comments: "Comments",
				genomicFeature: "Gene / Protein / QTL involved ",
				geo: "Scale/ geographical region",
				notCropCategory: "Other crop category / group",
				notSpecies: "Other species",
				orthologs: "Orthologues in other species",
				orthologsReference: "Orthologs references",
				pathway: "Pathway",
				reference: "Reference",
				review: "Review",
				technology: "Technologies and Methods",
				transferability: "Transferability potential / existing examples of transferability",
				transferabilityReference: "Transferability references",
				traits : "Traits"},

		filters :{},
		filterParameters:{},
		
		mutateOverview : function(value, data, type, params, component){
			if (data["attribute"] == "Review") {
				if (value == "0")
					return "No";
				if (value == "1")
					return "Yes";
			}	
			if (data["attribute"] == "Reference") {
				if (value.toUpperCase().startsWith("HTTP")){
					return value;
				}
				var doi = value.toUpperCase().indexOf("DOI");
				if (doi >= 0) {
					var doiString = value.substring(doi+3).trim();
					if (doiString.startsWith(":")) {
						doiString = doiString.substring(1);
					}
					if (doiString.endsWith(".")) {
						doiString = doiString.slice(0,-1);
					}
					if (doiString.startsWith("/")) {
						doiString = doiString.substring(1);
					}
					doiString = doiString.trim();
					doiString.slice(0,doiString.indexOf(" "));
					return "https://www.doi.org/" + doiString;
				}
			}
			return value;
		},
		rowClickOverview : function (event, row){
			var data = row.getData();
			if (data["attribute"] == "Reference") {
				if (data["value"].toUpperCase().startsWith("HTTP")){
					Object.assign(document.createElement("a"), {target: '_blank', href:data["value"],}).click();
				}
			}
		},		
		rowFormatterOverview: function(row) {
			var data = row.getData();
			if (data["attribute"] == "Reference") {
				row.getElement().style.color = "blue";
			}			
		},
		
		rowClick : function (event, row){
			var data = row.getData();
			$('#descriptionLongTitle')[0].innerHTML = data['title'];
			$('#description').modal('show');
			var tableData = [];
			for (var d in data) {
				if (d != "ID")
					tableData.push({attribute:Table.translate[d], value:data[d]});
			}
			Table.description = new Tabulator("#table_description", {
			    columns:[
			        {title:"Attribute", field:"attribute", sorter:"string"},
			        {title:"Value", field:"value", sorter:"string", mutator: Table.mutateOverview, formatter:"textarea"}
			    ],
			    data : tableData,
			    layout:"fitColumns",
			    rowClick: Table.rowClickOverview,
			    rowFormatter: Table.rowFormatterOverview
			});
			
		},
				
		categoryMutator: function(value, data, type, params, component) {
			value = value.join() + " ";
			return value;
		},
		
		results : function(data) {
			if ("cropbooster-p" in data) {
				data = [];
			}
			var currentFilters = undefined;
			if (Table.overview) {
				currentFilters = Table.overview.getFilters();
			}
			Table.overview = new Tabulator("#table_results", {
			    columns:[
			        {title:"Title", field:"title", sorter:"string", widthGrow:4},
			        {title:"Publication date", field:"year", sorter:"number"},
			        {title:"Category / group", field:"cropCategory", sorter:"string", mutatorData:Table.categoryMutator},
 			        {title:"Species", field:"species", sorter:"string"}
			    ],
			    data : data,
			    pagination:"local",
			    paginationSize:10,
			    rowClick : Table.rowClick,
			    layout:"fitColumns",
			    dataFiltered: function onFiltering(filters, rows) {Table.filterCallback(filters, rows)}
			});
			if (currentFilters){
				Table.overview.setFilter(currentFilters);
			}
		},

		clearFilters : function() {
			Table.filters = {};
			Table.filterParameters = {};
		},
		
		emptyFilter : function(target) {
			Table.filters[target] = [""];
		},
		
		activateFilters : function() {
			var currentFilters =Table.overview.getFilters();
			Table.filterParameters["cropCategory"] = Table.filters["cropCategory"]; 
			Table.overview.addFilter(Table.filterCategory);
			for (var tableFilter in Table.filters) {
				for (var f in currentFilters) {
					if (currentFilters[f].field == tableFilter) {
						var value = currentFilters[f].value;
						if (currentFilters[f].field != "cropCategory") 
							Table.overview.removeFilter(tableFilter, "in", value);
					}
				}
				if (tableFilter != "cropCategory") 
					Table.overview.addFilter(tableFilter, "in", Table.filters[tableFilter]);
			}
		},
		
		parseElement : function(element) {
			var newElement = new Object();
			
			newElement.field = element.id.split("_")[0];
			newElement.name = element.name;
			if (element.name == "null") {
				newElement.name = null;
			}
			else if (newElement.field == "year"){
				newElement.name = parseInt(element.name);
			}
			return(newElement);
			
		},
		
		filterCategory : function(data, filterParams) {
			if (! ("cropCategory" in Table.filterParameters))
				return true;
			var elem = data["cropCategory"].split(",");
			var found = false;
			for (var e in elem) {
				elem[e] = elem[e].trim();
				if (Table.filterParameters["cropCategory"]) {
					found = found || Table.filterParameters["cropCategory"].includes(elem[e]);
				}
			}
			return found;
		},
		
		filter : function(element) {
			var el = Table.parseElement(element);
			var value = []
			var currentFilters =Table.overview.getFilters();
			Table.overview.removeFilter(Table.filterCategory);
			if (el.name == "")
				return;
			for (var f in currentFilters) {
				if (currentFilters[f].field == el.field) {
					value = currentFilters[f].value;
					Table.overview.removeFilter(el.field, "in", value);
				}
				if (el.field == "cropCategory" && typeof currentFilters[f].field === "function") {
					if (! ("cropCategory" in Table.filterParameters)) {
						Table.filterParameters["cropCategory"] = [];
					}
					value = Table.filterParameters["cropCategory"];
				}
			}
			if (element.checked) {
				value.push(el.name);
			}
			else {
				value = value.filter(item => item != el.name);
			}
			if (el.field != "cropCategory") {
				Table.overview.addFilter(el.field, "in", value);
			}
			else {
				Table.filterParameters["cropCategory"] = value;
			}
			Table.overview.addFilter(Table.filterCategory);
			
		},
		
		
		addToFilter : function(element) {
			var el = Table.parseElement(element);
			if (!(el.field in Table.filters)) {
				Table.filters[el.field] = [];
			}
			Table.filters[el.field].push(el.name);
			
		},
		
		filterCallback : function(filters, rows) {
			$(resultsTitle).text("Results ("  + rows.length + ")");
			CBPChart.chartYear(rows);
			CBPChart.chartSpecies(rows);
			CBPChart.chartCropCategory(rows);
			Ontology.display(rows);
		} 
}

module.exports = Table;
