'use strict';
const rx = require('rxjs');
const rx_operators = require('rxjs/operators');
const axios = require("axios");

const Ontology = {

		postCall : function (url, data) {
			return new rx.Observable( ( observer ) => {
	            axios.post( url, data )
	                .then( ( response ) => {
	                    observer.next( response.data );
	                    observer.complete();
	                } )
	                .catch( ( error ) => {
	                    observer.error( error );
	                } );
	        });
	    	
	    },

	    print : function(data) {
	    	var tableData = "";
	    	var publications = {};
	    	for (var i in data) {
	    		var id = i.replace(/[\/ \(\),]/g,"_");
	    		var totalLength = 0;
	    		var localTable = "";
	    		for (var j in data[i]) {
	    			totalLength += data[i][j]["publication"].length;
	    			localTable += "<tr ><td>" + j + "</td><td align='right'>" + data[i][j]["publication"].length + "</td></tr>";
	    			for (var p in data[i][j]["publication"]) {
	    				if (! (data[i][j]["publication"][p] in publications)) {
	    					publications[data[i][j]["publication"][p]] = [];
	    				}
	    				publications[data[i][j]["publication"][p]].push(i + ": " + j);
	    			}
	    		}
	    		localTable += "</table></div></div>"	;
	    		localTable = '<div class="card-header" id="heading' + id + 
    			'"><h5 class="mb-0"><button class="btn btn-link" data-toggle="collapse" data-target="#' + id + 
    			'" aria-expanded="true" aria-controls="' + id + '">' + i +
    			' (' + totalLength + ')' +
    			'</button></h5></div><div id="' + id + '" class="collapse hidden" aria-labelledby="heading"' + id + 
    			'" data-parent="#ontologyCard"><div class="card-body"><table width="100%">' + localTable; 
	    		tableData += localTable;
	    	}
	    	$("#ontology").html(tableData);
	    	for (var p in publications) {
	    		for (var c in Ontology.currentData) {
	    			if (Ontology.currentData[c]._row.data.ID == p) {
	    				Ontology.currentData[c]._row.data["traits"] = publications[p].join("\n");
	    			}
	    		}
	    	}
	    },

	    getOntologies : function(data) {
	    	Ontology.currentData = data;
			var idList = [];
			for(var i =0; i < data.length ; i++) {
				idList.push(data[i]._row.data.ID);
			}
			return Ontology.postCall("/cropbooster-p/ontology/get.php", idList);

	    },
	    
		display : function (data) {
			var ontologyCall = Ontology.getOntologies(data);
			ontologyCall.subscribe(Ontology.print);
		},
		
		
}

module.exports = Ontology; 