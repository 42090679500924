var chartjs = require("chart.js");
var App = require("application.js");
const rx = require('rxjs');

document.addEventListener('DOMContentLoaded', function() {
  // do your setup here 
    var filterObserver = new rx.Observable( ( observer ) => {
        App.filters();
        App.getAllCategoryInPublication();
		observer.next();
		observer.complete();
		} );
	
	filterObserver.subscribe(App.getResults);
  
	
  console.log('Initialized app');
  
});
