'use strict';
var chartjs = require("chart.js");
const palette = require("google-palette");

const CBPChart = {
		yearRanges : [2000, 2010, 2015],
		
	    chartYear : function (data) {
	    	CBPChart.create("Years", "year", data);
		},
		
		chartSpecies : function (data) {
			CBPChart.create("Species", "species", data);
	    },

	    chartCropCategory : function (data) {
			CBPChart.create("CropCategory", "cropCategory", data);
	    },
	    
	    filterOnLegendEvent : function(event, legendItem) {
	    	// disable clicking, as the event is not passed properly
	    	return false;
	    },

	    filterOnEvent : function(event, activeElements) {
	    	// disable clicking, as the event is not passed properly
	    	return false;
	    }, 

	    create : function(label, filter, data) {
			var ctx = document.getElementById('chart_' + filter).getContext('2d');
			var chartData = {};
			var stats = {};
			if (label == "Years") {
				for(var i =0; i < data.length ; i++) {
					var value = data[i]._row.data[filter];
					var result = "" + value;
					if (value < CBPChart.yearRanges[0] )
						result = "before " + CBPChart.yearRanges[0];
					else if (value < CBPChart.yearRanges[1] )
						result = "between " + CBPChart.yearRanges[0] + " and " + CBPChart.yearRanges[1];
					else if (value < CBPChart.yearRanges[2] )
						result = "between " + CBPChart.yearRanges[1] + " and " + CBPChart.yearRanges[2];
						
						
				    if (!(result in stats)) {
				    	stats[result] = 0;
				    }
				    stats[result]++;
				    
				}				
				
			}
			else if (label == "Species"){
				for(var i =0; i < data.length ; i++) {
				    if (!(data[i]._row.data[filter] in stats)) {
				    	stats[data[i]._row.data[filter]] = 0;
				    }
				    stats[data[i]._row.data[filter]]++;
				    
				}				
			}
			else if (label == "CropCategory") {
				for(var i =0; i < data.length ; i++) {
					var elem = data[i]._row.data[filter].split(",");
					for (var e in elem) {
	
					    if (!(elem[e] in stats)) {
					    	stats[elem[e]] = 0;
					    }
					    stats[elem[e]]++;
					}				    
				}				
				
			}
			var pal = palette('mpn65', Object.keys(stats).length);
			var palRGB = [];
			for (var p in pal) {
			    palRGB.push("#"+ pal[p]);
			}
			if(CBPChart.myCharts == undefined)
				CBPChart.myCharts = [];
			if(CBPChart.myCharts[label] != undefined)
				CBPChart.myCharts[label].destroy();


			CBPChart.myCharts[label] = new Chart(ctx, {
			    type: 'doughnut',
			    data: {
			        labels: Object.keys(stats),
			        datasets: [{
			            label: label,
			            data: Object.values(stats),
			            backgroundColor: palRGB,
			            borderWidth: 1
			        }]
			    },
			    options: {
			    	onClick : CBPChart.filterOnEvent,
			    	legend: {
			    		onClick: CBPChart.filterOnLegendEvent
			    	}
			    }
			});
			CBPChart.myCharts[label].update();
	    },
	
}

module.exports = CBPChart;