'use strict';
var Table = require('table');
var CBPChart = require('cb-p-chart');
const rx = require('rxjs');
const rx_operators = require('rxjs/operators');
const axios = require("axios");
const {Parser} = require("json2csv");
const {json2csv} = require("json-2-csv");

const App = {
		
	
	tableResults : function (data) {
    	App.data = data;
    	App.linkToCategory(data);
    	var filterObserver = new rx.Observable( ( observer ) => {
    		Table.results(data);    
    		observer.next(data);
    		observer.complete();
    		} );
    	
    	filterObserver.subscribe(App.tickFilterBoxes);
    },

    
    getCall : function (url) {
		return new rx.Observable( ( observer ) => {
            axios.get( url )
                .then( ( response ) => {
                    observer.next( response.data );
                    observer.complete();
                } )
                .catch( ( error ) => {
                    observer.error( error );
                } );
        });
    	
    },
    
    
    getSpecies : function() {
    	return App.getCall('/cropbooster-p/species/get.php');    	
    },

    getYear : function() {
    	return App.getCall('/cropbooster-p/year/get.php');	
    },
    
    getCategory : function() {
    	return App.getCall('/cropbooster-p/category/get.php');    	
    },

    getCategoryInPublication : function() {
    	return App.getCall('/cropbooster-p/category/getPublications.php');    	
    },
    
    getPublications : function() {
    	return App.getCall('/cropbooster-p/publications/get.php');
    },

    searchCall : function(search) {
		return new rx.Observable( ( observer ) => {
	            axios.get( '/cropbooster-p/search/text.php', {params: {search : search}})
	                .then( ( response ) => {
	                    observer.next( response.data,  );
	                    observer.complete();
	                } )
	                .catch( ( error ) => {
	                    observer.error( error );
	                } );
	        });
	
    },

   
    getResults : function() {
        var observable = App.getPublications().pipe(rx_operators.share());
        observable.subscribe(App.tableResults);

    },

    linkToCategory : function(data) {
    	if (App.categoryInPublication) {
	    	for (var i in data) {
	    		data[i].cropCategory = App.categoryInPublication[data[i].ID];
	    	}
    	}
    },
    
    storeAllCategoryInPublication : function(data) {
    	App.categoryInPublication = [];
    	for (var i in data) {
    		if (! (data[i].ID in App.categoryInPublication)) {
    			App.categoryInPublication[data[i].ID] = [];	
    		}
    		App.categoryInPublication[data[i].ID].push(data[i].name);
    	}
    },
    
    getAllCategoryInPublication : function() {
        var observable = App.getCategoryInPublication().pipe(rx_operators.share());
        observable.subscribe(App.storeAllCategoryInPublication);
    },
    
    
    search: function(elem, event) {
        var observable = App.searchCall(elem.value).pipe(rx_operators.share());
        observable.subscribe(App.tableResults);
    },
    
    filter : function(element) {
    	Table.filter(element);
    	
    },
    
    tickBox : function(label, element, checked) {
		var text = element[label]; 
		if (element[label] == null ) {
			text = "Unknown / N.A.";
		}
		var filterLabel = label + '_' + text;
		if (filterLabel in App.filterBoxes) { 
			App.filterBoxes[filterLabel].checked = checked;
			App.filterBoxes[filterLabel].disabled = false;
			Table.addToFilter(App.filterBoxes[filterLabel]);
		}
		
    },
    
    tickFilterBoxes : function(data) {
    	App.filterBoxesSwitch(data, true);
    },
    
    filterBoxesSwitch : function(data, checked) {
    	Table.clearFilters();
    	for (var i in App.filterBoxes) {
    		App.filterBoxes[i].checked = false;
    		App.filterBoxes[i].disabled = true;
    	}
    	for (var i in data) {
    		if ("species" in data[i]) {
    			App.tickBox("species", data[i], checked);
    		} 
    		if ("year" in data[i]) {
    			App.tickBox("year", data[i], checked);
    		} 
    		if ("cropCategory" in data[i]) {
    			var categories = data[i]["cropCategory"].split(',');
    			for (var elem in categories) {
    				var dataElement = [];
    				dataElement["cropCategory"] = categories[elem].trim();
    				App.tickBox("cropCategory", dataElement, checked);
    			}
    		} 
    	}
    	Table.activateFilters();
    },

    filterBoxesSelect : function(target, checked) {
    	Table.clearFilters();
    	for (var form in document.forms){
    		var formElements = document.forms[form].elements;
	    	for (var element in formElements) {
	    		if (formElements[element].type=="checkbox" && ! formElements[element].disabled  && formElements[element].checked) {
	    			Table.addToFilter(formElements[element]);
	    		}
	    		
	    	}
    	}
    	if (! checked)
    		Table.emptyFilter(target);
    	Table.activateFilters();
    },

    
    buildFilter : function (label, data) {
    	if (!App.filterBoxes) {
    		App.filterBoxes = {};
    	}
    	var filter = $("#"+label+"_list");
    	for (var s in data) {
    		if (((label != "year" && data[s][label].length > 0) || (label == "year"))) {
    			var text = data[s][label]; 
    			if (data[s][label] == null ) {
    				text = "Unknown / N.A.";
    			}
	        	var newCheckboxHTML = 
	        		'<div class="form-check"><input class="form-check-input" id="' + 
	        		label + '_' + text + '" name="'+data[s][label]+'" type="checkbox" onChange="App.filter(this);">' + 
	        		'<label for="' + label + '_' + text + '" class="form-check-label">' +  
	        		text + "</label></div>";
	        	var newCheckbox = $(newCheckboxHTML);
	        	$(filter).after(newCheckbox);
	        	App.filterBoxes[label + '_' + text] = newCheckbox[0].childNodes[0];

    		}
    	}
    	
    },
    
    filterSpecies: function (data) {
    	App.buildFilter("species", data);
    },

    filterYear: function (data) {
    	App.buildFilter("year", data);
    },

    filterCategory: function (data) {
    	App.buildFilter("cropCategory", data);
    },

    filters: function() {
        var observable = App.getSpecies().pipe(rx_operators.share());
        observable.subscribe(App.filterSpecies);
        
        observable = App.getYear().pipe(rx_operators.share());
        observable.subscribe(App.filterYear);
        
        observable = App.getCategory().pipe(rx_operators.share());
        observable.subscribe(App.filterCategory);
    	
    },
    
    select: function(target) {
    	var label = $("span#" + target + "_select").text();
    	var form = document.forms[target + "_form"];
    	var value = true;
    	if (label == "deselect all") {
    		$("span#" + target + "_select").text("select all");
    		value = false;
    	}
    	else {
    		$("span#" + target + "_select").text("deselect all");    		
    	}
    	for (var i in form.elements) {
    		if (form.elements[i].type == "checkbox" && ! form.elements[i].disabled) {
    			var currentValue = form.elements[i].checked;
    			form.elements[i].checked = value;
    		}
    	}
        App.filterBoxesSelect(target, value);

    },
    downloadURL: function (url, name) {
    	  var link = document.createElement("a");
    	  link.download = name;
    	  link.href = url;
    	  document.body.appendChild(link);
    	  link.click();
    	  document.body.removeChild(link);
    },
    	
    json2csvCallback : function (error, csv){
    	if (error) throw error;
    	var blob = new Blob([csv], {type:"text/csv;charset=utf-8"});
    	var url = window.URL.createObjectURL(blob);
    	App.downloadURL(url, "cropbooster-p.csv");
    },
    toCSV : function() {
    	/*
    	const json2csvParser = new Parser({withBOM: true, excelStrings:true})
    	var csv = encodeURI("data:text/csv;charset=utf-8," + json2csvParser.parse(App.data)); //json2csv.parse(App.data));
    	window.open(csv);
    	*/
    	json2csv(App.data, App.json2csvCallback);
    	
    }
    
}

module.exports = App;
